export const useOpenaiStore = defineStore('openai', {
  state: () => ({
    contentId: null,
    inputValue: '',
    messages: [],
    openaiChatPanelOpen: false,
    roles: [
      {
        id: 1,
        name: 'Short Description',
        system: 'You are a travel guide and travel writer.',
        user: 'Write a short description of not more than 128 characters length to a global travel guide audience.  Your job is to present and attract humans to this place. Write from the perspective that you are at this place. It should pick the most import or entertaining information of the following text while providing a general overview, do not use the name of the place, use positive wording, be informal, do not use verbs, make exactly one sentence, and do not write any facts which is not present here:\n\n',
        iconName: '',
      },
      {
        id: 1,
        name: 'Feed catch line',
        system: 'You are a travel guide and travel writer.',
        user: 'Write a short, catchy sentence of not more than 132 characters length to a global travel guide audience.  Your job is to attract people and rise their interest. You are funny and may formulate your claim as question. Write from the perspective that you are at this place. It should pick up the topics of the following text, without naming the place itself, use positive wording, be informal but keep a high niveau, make one sentence, and not write any facts which is not present here. Do not put it into double quotation mark. If present, you can use background information:\n\n',
        iconName: '',
      },
    ],
  }),
  actions: {
    appendMessage(message) {
      this.messages.push(message)
    },
    getMessages(id) {
      const index = this.messages.findIndex((m) => m.id === id)
      if (index === -1) {
        return []
      }
      return this.messages[index].messages
    },
    setMessages(id, messages) {
      const index = this.messages.findIndex((m) => m.id === id)
      if (index === -1) {
        this.messages.push({ id, messages })
      } else {
        this.messages[index].messages = messages
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOpenaiStore, import.meta.hot))
}
